.about-page-container {
    margin-top: 50px;
    background-image: url("facets.png");
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: space-between;
    text-align: center;
    position: relative;

}

.slideshow {
    width: 90%;
    margin: 50px;
    text-align: left;
}

.about-page-images {
    position: absolute;
    bottom: 0;
    left: 0;
    width: inherit;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-left: 5px;
    padding: 5px;
    z-index: 1; /* Ensure the images appear above the background */
}

.about-page-images h2 {
    color: rgb(255, 255, 255);
    padding: 10px;
    margin: 5px 0;
    background-color: rgb(82, 197, 167);
    border: 0.5px solid rgb(0, 0, 0);
    border-radius: 10px;
}

.about-page-images img {
    max-height: 39vh;
    border: 0.5px solid rgb(0, 0, 0);
    border-radius: 10px;
}

#swap-text::before {
    content: " on trips.";
    color: rgb(28, 194, 159);
    font-size: 24pt;
    font-weight: bolder;
    animation: swap-text 12s ease-in infinite;
}

@keyframes swap-text {
    0% {
        content: " on trips.";
        color: rgb(28, 194, 159);
    }
    25% {
        content: " with housemates.";
        color: rgb(134, 86, 205);
    }
    50% {
        content: " with your partner.";
        color: rgb(166, 0, 47);
    }
    75% {
        content: " with anyone.";
        color: rgb(28, 194, 159);
    }
}

.signup-button {
    background-color: rgb(82, 197, 167);
    color: rgb(255, 255, 255);
    margin-top: 20px;
    padding: 15px 60px;
    border-radius: 5px;
    animation: swap-color 12s ease-in infinite;
}

@keyframes swap-color {
    0% {
        background-color: rgb(28, 194, 159);
    }
    25% {
        background-color: rgb(134, 86, 205);
    }
    50% {
        background-color: rgb(166, 0, 47);
    }
    75% {
        background-color: rgb(28, 194, 159);
    }
}
