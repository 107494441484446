.left-nav-bar {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: fixed;
    top: 50px;
    left: 0;
    width: 200px;
    padding: 10px 0px;
}

.left-nav-bar * {
    font-size: 12pt;
}

.dashboard-logo {
    width: 20px;
    height: 20px;
}

.left-nav-bar > a > * {
    padding: 0px 5px;
}

.friends-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(238, 238, 238);
    padding-left: 5px;
}

.friends-list-header > button {
    border: none;
    background: none;
}

.friends-list-header > button:hover {
    color: rgb(82, 197, 167);
}

.active-nav-link {
    color: rgb(82, 197, 167);
}
