/* TODO Add site wide styles */

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%
}

* {
    font-family: 'Montserrat', sans-serif;
    font-size: 12pt;
    font-style: normal;
}

.hidden {
    display: none;
}

h1 {
    font-size: 24pt;
}

h2 {
    font-size: 18pt;
}

button {
    font-size: 12pt;
    font-style: normal;
    width: auto;
    margin: 5px 0px;
    border-radius: 5px;
    border-width: 1px;
    padding: 8px;
}

button:hover {
    cursor: pointer;
}

button.normal {
    background-color: rgb(255, 255, 255);
    color: rgb(82, 197, 167);
    border: none;
}

button.accent {
    background-color: rgb(255, 102, 56);
    color: rgb(255, 255, 255);
    border-color: rgb(233, 92, 50);
}

button.primary {
    background-color: rgb(82, 197, 167);
    color: rgb(255, 255, 255);
    border-color: rgb(75, 177, 151);
}

input {
    box-sizing: border-box;
}

form {
    display: flex;
    justify-content: left;
    flex-direction: column;
}

.error-msg {
    color: rgb(255, 0, 0);
}

a {
    text-decoration: none;
    color: rgb(0, 0, 0);
}

a:hover {
    cursor: pointer;
}

.button:disabled{
    color:rgb(128, 128, 128)
}
