.signup-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.signup-form {
    width: 400px;
    height: 400px;
    display: flex;
    padding: 10px 30px;
    flex-direction: column;
    justify-content: space-around;
}

.signup-logo {
    width: 400px;
}
