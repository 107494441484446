.add-friend-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.add-friend-form * {
    box-sizing: border-box;
    margin: 5px 0px;
    width: 100%;
}

.add-friend-button {
    width: 300px;
    align-self: flex-end;
}

.add-friend-logo {
    width: 30px;
    height: 30px;
    margin: 0px 3px;
}
