p, ul, div, li, a, img, h1, h2, h3, h4, section {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

.comment-icon {
    height: 15px;
    width: 15px;
    margin-right: 3px;
}

.expense-subheader {
    display: flex;
    gap: 10px;
    padding: 5px;
}

.expense-subheader-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 3px;
}

.expense-subheader-text-wrapper button {
    background-color: rgb(255, 102, 56);
    color: rgb(255, 255, 255);
    border-color: rgb(233, 92, 50);
    padding: 5px;
    border-radius: 3px;
    font-size: 11px;
    width: 100px;
}

.expense-subheader-description {
    font-size: 16px;
    color: rgb(60, 65, 68);
}

.expense-subheader-amount {
    font-size: 20px;
    font-weight: bold;
    color: rgb(60, 65, 68);
}

.expense-subheader-date {
    font-size: 12px;
    color: rgb(153, 153, 153);
}

.expense-main-content {
    width: 90%;
    padding: 0px 10px 8px;
}

.expense-main-content-wrapper img,
.expense-content-wrapper img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.expense-edit-btn {
    max-width: 85px;

}

.expense-post-btn {
    width: 75px;
    text-align: center;
}

.expense-image-wrapper {
    width: 84px;
    height: 84px;
    border: 1px rgb(221, 221, 221) solid;
    align-self: center;
}


.expense-image-wrapper img {
    height: 84px;
    width: 84px;
}

.expense-main {
    display: flex;
    gap: 10px;
}

.expense-main-content-wrapper, .expense-main-content-wrapper li {
    display: flex;
    gap: 10px;
    align-items: center;
}

.expense-main-content-wrapper span {
    font-weight: bold;
    font-size: 13px;
}

.expense-main-content-wrapper p {
font-size: 14px;
}

.expense-main-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.expense-content-wrapper {
    margin: 5px 0px 8px;
}

.expense-comment {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.expense-comment-text {
    font-size: 13px;
    font-weight: bold;
    color: rgb(60, 65, 68);
    margin: 5px 0px;
}

.expense-comment-item {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    padding: 6px 8px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 8px;
    word-wrap: break-word;
    position: relative;
}

.expense-comments-wrapper {
    box-sizing: border-box;
    padding: 5px;
    background-color: rgb(248, 248, 248);
    box-shadow: rgba(24, 26, 27, 0.25) 0px 5px 7px -5px inset, rgba(24, 26, 27, 0.25) 0px -3px 7px -5px inset;
    width: 100%;
}

.expense-icon-wrapper {
    position: absolute;
    right: 5px;
    top: 0;
    display: flex;
    gap: 7px;
    align-items: center;
    padding: 3px 0;
}

.expense-icon-wrapper > * {
    cursor: pointer;
}

.expense-icon-wrapper > div:first-child {
    display: flex;
    align-items: center;
}

.expense-edit {
    width: 10px;
    height: 10px;
    font-size: 10px;
    vertical-align: auto;
}

.expense-delete {
    font-size: 12px;
    color: rgb(143, 61, 61);
}

.expense-close:hover {
    text-decoration: underline;
}

.expense-comment-title {
    font-weight: bold;
    color: rgb(60, 65, 68);
    font-size: 13px;
}

.expense-comment-span {
    color: rgb(148, 142, 133);
    font-size: 11px;
    margin-left: 3px;
}

.expense-comment-form {
    display: flex;
    flex-direction: column;
}

.expense-comment-form textarea {
    border-color: rgb(184, 180, 174);
}

.expense-comment-form .expense-error {
    font-size: 11px;
    color: rgb(143, 61, 61);
}

.expense-button-wrapper {
    display: flex;
    gap: 10px;
}

textarea {
    box-sizing: border-box;
    width: 100%;
}
