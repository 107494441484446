.login-form-container {
    background-image: url("facets.png");
    background-repeat: repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.login-form {
    background: white;
    width: 400px;
    height: 400px;
    padding: 20px 50px;
    border-radius: 5%;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 5px gray;
}
