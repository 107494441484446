.main-header {
    margin-top: 50px;
    margin-left: 200px;
    margin-right: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    background-color: rgb(238, 238, 238);
}

.button:disabled{
    color: rgb(128, 128, 128);
}

.main-header-title {
    display: flex;
    align-items: center;
}

.main-header-title * {
    font-size: 16pt;
    padding: 0px 5px;
    object-fit: cover;
}

.main-header-buttons > button {
    margin: 0px 5px;
}

.main-header-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.main-header-buttons > button:first-child {
    background-color: rgb(255, 102, 56);
    color: rgb(255, 255, 255);
    border-color: rgb(233, 92, 50);
}

.main-header-buttons > button:nth-child(2) {
    background-color: rgb(82, 197, 167);
    color: rgb(255, 255, 255);
    border-color: rgb(75, 177, 151);
}

.main-header-buttons:disabled {
    background-color: rgb(128, 128, 128);
    opacity: 0.5;
}
