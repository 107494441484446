.add-edit-expense-form {
  padding: 20px;
}

.add-edit-expense-form h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}

.friend-selection {
  margin-bottom: 15px;
}

.selected-friends {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.selected-friend {
  display: flex;
  align-items: center;
  background-color: rgb(226, 226, 226);
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.selected-friend button.remove-button {
  margin-left: 5px;
  background-color: transparent;
  border: none;
  color: rgb(136, 136, 136);
  cursor: pointer;
}

.friend-list {
  margin-top: 10px;
}

.friend {
  padding: 5px;
  cursor: pointer;
}

.friend.selected {
  background-color: rgb(238, 238, 238);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
  gap: 10px;
}

.cancel-button,
.save-button {
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
