.edit-friend-form {
    margin: 50px auto 0px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    row-gap: 10px;
}

.edit-friend-text {
    text-align: center;
}

.edit-friend-buttons {
    margin: 15px 0px;
}

.edit-friend-buttons button {
    margin: 0px 5px;
}
